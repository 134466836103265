<template>
  <SearchHotelUI @chooseCity="chooseCity" @chooseTicket="chooseTicket"/>
</template>

<script>
import SearchHotelUI from "./components/SearchHotelUI";
export default {
  name: "SearchHotelView",
  components: { SearchHotelUI },
  methods: {
    // 选城市
    chooseCity(vuxFunction = "hotel/setHotelOrderFrom") {
      this.$router.push({
        path: "/selectCity",
        query: { function: vuxFunction, allCity: true }
      });
    },

    // 选票
    chooseTicket(){}
  }
};
</script>
